import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
      '*.firebase.com',
      '*.googleapis.com',
      '*.google.com',
      '*.gstatic.com',
      '*.google-analytics.com',
      '*.googletagmanager.com',
      '*.googleusercontent.com',
      '*.firebaseio.com',
      '*.appspot.com',
      '*.cloudfront.net',
      '*.intercom.io',
      '*.intercomcdn.com',
      '*.airbridge.io',
      '*.hotjar.com',
      '*.hotjar.io',
      '*.facebook.com',
      '*.tiktok.com',
    ],
    instrumentationKey: import.meta.env.VITE_AZURE_INSTRUMENTATION_KEY,
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || [];
  env.tags['ai.cloud.role'] = 'testTag';
});

export { appInsights, reactPlugin };

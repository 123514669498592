import useAccountContext from '@/hooks/context/useAccountContext';

export const useLocalStorage = () => {
  const { accountId } = useAccountContext();

  const keyFormatter = (key: string) => {
    return `${accountId}-${key}`;
  };

  const setItem = (key: string, value: unknown) => {
    return localStorage.setItem(keyFormatter(key), JSON.stringify(value));
  };
  const getItem = (key: string) => {
    const item = localStorage.getItem(keyFormatter(key));
    return item ? JSON.parse(item) : null;
  };
  const removeItem = (key: string) => {
    return localStorage.removeItem(keyFormatter(key));
  };

  return { setItem, getItem, removeItem, accountId };
};
